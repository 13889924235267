import React from 'react'
import Login from './Pages/Login'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Dashboard from './Pages/Dashboard/Dashboard'
import Loading from './Pages/Loading'

export const navigationPath = {
  dashboard: '/dashboard',
  login: '/login',
  logout: '/logout',
}

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Loading />} />
        <Route path='/' element={<Navigate to='/login' />} />
        <Route path='/login' element={<Login />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/logout' element={<Navigate to='/login' />} />
        <Route path='*' element={<Navigate to='/login' />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
