import { SaveOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd';

interface FloatingSaveButtonProps {
  onClick: () => void;
}

const FloatingSaveButton: React.FC<FloatingSaveButtonProps> = ({ onClick }) => {
  return (
    <FloatButton
      icon={<SaveOutlined rev={undefined} className='text-2xl' />}
      type='primary'
      className='w-20 h-20'
      onClick={onClick}
    />
  );
};

export default FloatingSaveButton;
