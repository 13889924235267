import { useEffect, useState, useRef } from 'react'
import { DateTime } from 'luxon'
import { Range } from 'react-date-range'
import DateRangePickerReferral from './Components/DateRangePicker'

const Referral = () => {
  const [data_referral, set_data_referral] = useState<any>([])

  //* Range Date Picker State

  const [selectionRange, setSelectionRange] = useState<Range>({
    startDate: DateTime.now().toJSDate(),
    endDate: DateTime.now().toJSDate(),
    key: 'selection',
  })

  const [start_date, set_start_date] = useState<DateTime>(DateTime.now())

  const [end_date, set_end_date] = useState<DateTime>(DateTime.now())

  const [display_date_range_picker, set_display_date_range_picker] =
    useState<boolean>(false)

  const date_range_picker_ref = useRef<HTMLDivElement>(null)

  const toggleDateRangePicker = () => {
    if (!display_date_range_picker) {
      set_display_date_range_picker(true)
    } else {
      date_range_picker_ref.current?.classList.replace(
        'fade-in-top',
        'fade-out-top'
      )
      if (selectionRange.startDate)
        set_start_date(DateTime.fromJSDate(selectionRange.startDate))
      if (selectionRange.endDate)
        set_end_date(DateTime.fromJSDate(selectionRange.endDate))

      setTimeout(() => {
        set_display_date_range_picker(false)
      }, 300)
    }
  }

  //*  State Banner Slot Demo

  useEffect(() => {
    fetch(
      `/api/cms/get-all-data-referral?start=${start_date.toFormat(
        'yyyy-MM-dd'
      )}&end=${end_date.toFormat('yyyy-MM-dd')}`
    )
      .then(response => response.json())
      .then(res => {
        set_data_referral(res.data)
      })
  }, [start_date, end_date])

  return (
    <div onClick={toggleDateRangePicker}>
      <div>
        {display_date_range_picker && (
          <div
            className='fixed left-0 top-0 right-0 bottom-0 z-10'
            onClick={toggleDateRangePicker}
          />
        )}
        <div
          className='mb-2'
          onClick={e => {
            e.stopPropagation()
          }}
        >
          <DateRangePickerReferral
            toggleDateRangePicker={toggleDateRangePicker}
            selectionRange={selectionRange}
            setSelectionRange={setSelectionRange}
            displayDateRangePicker={display_date_range_picker}
            dateRangePickerRef={date_range_picker_ref}
          />
        </div>
        <div className='mb-8 border rounded-md p-2'>
          <table className='table-auto w-full'>
            <thead>
              <tr className='bg-gray-200'>
                <th className='px-4 py-2'>Referral</th>
                <th className='px-4 py-2'>Player Registered</th>
              </tr>
            </thead>
            <tbody>
              {data_referral.map((item: any, index: any) => (
                <tr key={index} className='border-t'>
                  <td className='px-4 py-2 text-center align-middle'>
                    {item.referral}
                  </td>
                  <td className='px-4 py-2 text-center align-middle'>
                    {item.total}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Referral
