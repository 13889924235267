import React, { useState } from 'react';

interface UploadTextfieldProps {
  title: string;
  value: string;
  onChange: (value: string) => void;
  onFileSelect: (file: File) => void;
  indexProps: number;
}

const UploadTextfield: React.FC<UploadTextfieldProps> = ({ title, value, onChange, onFileSelect, indexProps }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log({ selectedFile });

    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
      onFileSelect(event.target.files[0]);
    }
  };

  return (
    <div className="mb-5">
      <label className="mb-3 block text-base font-medium text-[#07074D]">{title}</label>
      <div className="flex items-center space-x-3">
        <input
          disabled
          className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
          placeholder="Enter a URL or upload a file"
          value={value}
          onChange={(event) => onChange(event.target.value)}
        />
        <label
          htmlFor={`file-upload-${indexProps}`}
          className="flex items-center py-3 px-3 rounded-md border border-[#e0e0e0] bg-white text-[#6B7280] hover:bg-[#F3F4F6] hover:text-[#6A64F1] cursor-pointer"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
          <span>Upload</span>
          <input id={`file-upload-${indexProps}`} type="file" accept=".jpg,.jpeg,.png,.gif" className="hidden" onChange={handleFileInput} />
        </label>
      </div>
    </div>
  );
};

export default UploadTextfield;
