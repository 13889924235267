import UploadTextfield from '../../Components/Textfields/UploadTextfield';
import NormalTextfield from '../../Components/Textfields/NormalTextfield';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { navigationPath } from '../../App';
import FloatingSaveButton from './Components/floating-save-button';
import { Helmet } from 'react-helmet';

interface data7hari {
  deposit: number;
  login: number;
  withdraw: number;
  date: string;
  register:number;
}

const Home = () => {
  const navigate = useNavigate();

  const [id, setId] = useState('');
  const [banner1, setBanner1] = useState('');
  const [banner2, setBanner2] = useState('');
  const [banner3, setBanner3] = useState('');
  const [linkRTP,setLinkRTP] = useState('')
  const [bannerLiveGames, setBannerLiveGames] = useState('');
  const [livedrawSingapore, setLivedrawSingapore] = useState('');
  const [livedrawCambodia, setLivedrawCambodia] = useState('');
  const [livedrawChina, setLivedrawChina] = useState('');
  const [livedrawSydney, setLivedrawSydney] = useState('');
  const [livedrawHongkong, setLivedrawHongkong] = useState('');
  const [livedrawMacau, setLivedrawMacau] = useState('');
  const [livedrawTaiwan, setLivedrawTaiwan] = useState('');
  const [livedrawSingaporeToto, setLivedrawSingaporeToto] = useState('');
  const [livechat, setLivechat] = useState('');
  const [maintenance, setMaintenance] = useState(false);
  const [dataPengeluaran, setDataPengeluaran] = useState('');
  const [data7hari, setData7hari] = useState<data7hari[]>([]);

  const [namaWeb, setNamaWeb] = useState('');

  const [uploading, setUploading] = useState(false);

  const handleFileSelect1 = async (file: File) => {
    setUploading(true);
    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await fetch('/api/cms/upload-1', {
        method: 'POST',
        body: formData,
      });

      const res = await response.json();

      if (!response.ok) {
        throw new Error(res.errors[0]?.message || res.message);
      }

      alert('Berhasil Upload');

      navigate(navigationPath.dashboard);
      setUploading(false);
    } catch (error: any) {
      alert(error.message || 'Terjadi kesalahan ketika fetch.');
    }
  };

  const handleFileSelect2 = async (file: File) => {
    setUploading(true);
    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await fetch('/api/cms/upload-2', {
        method: 'POST',
        body: formData,
      });

      const res = await response.json();

      if (!response.ok) {
        throw new Error(res.errors[0]?.message || res.message);
      }

      alert('Berhasil Upload');

      navigate(navigationPath.dashboard);
      setUploading(false);
    } catch (error: any) {
      alert(error.message || 'Terjadi kesalahan ketika fetch.');
    }
  };

  const handleFileSelect3 = async (file: File) => {
    setUploading(true);

    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await fetch('/api/cms/upload-3', {
        method: 'POST',
        body: formData,
      });

      const res = await response.json();

      if (!response.ok) {
        throw new Error(res.errors[0]?.message || res.message);
      }

      alert('Berhasil Upload');

      navigate(navigationPath.dashboard);
      setUploading(false);
    } catch (error: any) {
      alert(error.message || 'Terjadi kesalahan ketika fetch.');
    }
  };

  const handleFileSelect4 = async (file: File) => {
    setUploading(true);

    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await fetch('/api/cms/upload-games', {
        method: 'POST',
        body: formData,
      });

      const res = await response.json();

      if (!response.ok) {
        throw new Error(res.errors[0]?.message || res.message);
      }

      alert('Berhasil Upload');

      navigate(navigationPath.dashboard);
      setUploading(false);
    } catch (error: any) {
      alert(error.message || 'Terjadi kesalahan ketika fetch.');
    }
  };

  useEffect(() => {
    fetch(`/api/cms/get-data-home`)
      .then(response => response.json())
      .then(res => {
        setId(res.data.id);
        setBanner1(res.data.banner1);
        setBanner2(res.data.banner2);
        setBanner3(res.data.banner3);
        setLinkRTP(res.data.linkRTP);
        setBannerLiveGames(res.data.bannerLiveGames);
        setLivedrawSingapore(res.data.livedrawSingapore);
        setLivedrawCambodia(res.data.livedrawCambodia);
        setLivedrawChina(res.data.livedrawChina);
        setLivedrawHongkong(res.data.livedrawHongkong);
        setLivedrawMacau(res.data.livedrawMacau);
        setLivedrawSydney(res.data.livedrawSydney);
        setLivedrawTaiwan(res.data.livedrawTaiwan);
        setLivedrawSingaporeToto(res.data.livedrawSingaporeToto);
        setLivechat(res.data.livechat);
        setMaintenance(res.data.maintenance);
        setDataPengeluaran(res.data.dataPengeluaran);
        setNamaWeb(res.namaWeb);
      });
  }, [uploading]);

  useEffect(() => {
    fetch(`/api/cms/get-all-data-user-7day`)
      .then(response => response.json())
      .then(res => {
        setData7hari(res.data7hari);
      });
  }, []);

  const save = async () => {
    try {
      const response = await fetch(`/api/cms/update-home`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          maintenance,
          banner1,
          banner2,
          banner3,
          bannerLiveGames,
          livedrawSingapore,
          livedrawCambodia,
          livedrawChina,
          livedrawHongkong,
          livedrawMacau,
          livedrawSydney,
          livedrawTaiwan,
          livedrawSingaporeToto,
          livechat,
          dataPengeluaran,
          linkRTP,
          id,
        }),
      });
      const res = await response.json();
      if (!response.ok) throw new Error(res.errors[0].message || res.message);
      alert('Berhasil Update');

      navigate(navigationPath.dashboard);
    } catch (error: any) {
      alert(error.message || `Terjadi kesalahan ketika menyimpan data home`);
    }
  };

  return (
    <div className='flex '>
      <div className='w-1/2 border-r border-gray-300 pr-4'>
        <Helmet>
          <title>[ {namaWeb} ] - APP ANTI NAWALA CMS</title>
          <meta name='description' content='App Description' />
          <meta name='theme-color' content='#008f68' />
        </Helmet>
        <FloatingSaveButton onClick={save} />
        <UploadTextfield title='Banner 1' value={banner1} onChange={setBanner1} onFileSelect={handleFileSelect1} indexProps={1} />
        <UploadTextfield title='Banner 2' value={banner2} onChange={setBanner2} onFileSelect={handleFileSelect2} indexProps={2} />
        <UploadTextfield title='Banner 3' value={banner3} onChange={setBanner3} onFileSelect={handleFileSelect3} indexProps={3} />
        <UploadTextfield
          title='Banner Live Games'
          value={bannerLiveGames}
          onChange={setBannerLiveGames}
          onFileSelect={handleFileSelect4}
          indexProps={4}
        />
        <NormalTextfield title='URL Live Chat' value={livechat} onChange={setLivechat} />
        <NormalTextfield title='URL Data Pengeluaran' value={dataPengeluaran} onChange={setDataPengeluaran} />
        <NormalTextfield title='URL RTP' value={linkRTP} onChange={setLinkRTP} />
        <div className='flex flex-row items-center justify-between mb-2 bg-slate-400 rounded-md p-2'>
          <label className='my-2  text-base font-medium text-[#07074D]'>Maintenance</label>
          <label className='relative inline-flex items-center cursor-pointer'>
            <input
              type='checkbox'
              value=''
              className='sr-only peer'
              checked={maintenance}
              onChange={() => setMaintenance(!maintenance)}
            />
            <div className="w-11 h-6 bg-gray-700 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-blue-600"></div>
            <span className=' text-sm font-medium text-gray-300 '></span>
          </label>
        </div>

        <div className='mb-8 border rounded-md p-2'>
          <table className='table-auto w-full'>
            <thead>
              <tr className='bg-gray-200'>
                <th className='px-4 py-2'>Date</th>
                <th className='px-4 py-2'>Deposit</th>
                <th className='px-4 py-2'>Login</th>
                <th className='px-4 py-2'>Withdraw</th>
                <th className='px-4 py-2'>Register</th>
              </tr>
            </thead>
            <tbody>
              {data7hari.map((item, index) => (
                <tr key={index} className='border-t'>
                  <td className='px-4 py-2 text-center align-middle'>
                    {new Date(new Date(item.date).getTime() - 7 * 60 * 60 * 1000).toLocaleDateString('en-GB')}
                  </td>

                  <td className='px-4 py-2 text-center align-middle'>{item.deposit}</td>
                  <td className='px-4 py-2 text-center align-middle'>{item.login}</td>
                  <td className='px-4 py-2 text-center align-middle'>{item.withdraw}</td>
                  <td className='px-4 py-2 text-center align-middle'>{item.register}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <hr className='flex-grow border-t border-gray-300'></hr>
      <div className='w-1/2 pl-4'>
        <NormalTextfield title='URL Live Draw Sydney' value={livedrawSydney} onChange={setLivedrawSydney} />
        <NormalTextfield title='URL Live Draw Singapore' value={livedrawSingapore} onChange={setLivedrawSingapore} />
        <NormalTextfield title='URL Live Draw Hongkong' value={livedrawHongkong} onChange={setLivedrawHongkong} />
        <NormalTextfield title='URL Live Draw Cambodia' value={livedrawCambodia} onChange={setLivedrawCambodia} />
        <NormalTextfield title='URL Live Draw China' value={livedrawChina} onChange={setLivedrawChina} />
        <NormalTextfield title='URL Live Draw Taiwan' value={livedrawTaiwan} onChange={setLivedrawTaiwan} />
        <NormalTextfield title='URL Live Draw Macau' value={livedrawMacau} onChange={setLivedrawMacau} />
        <NormalTextfield title='URL Live Draw Singapore Toto' value={livedrawSingaporeToto} onChange={setLivedrawSingaporeToto} />
      </div>
    </div>
  );
};

export default Home;
