import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { navigationPath } from '../App'
import SpinningLoader from '../Components/SpinningLoading'
import { MdSettings } from 'react-icons/md'

const Login: React.FC = () => {
  const navigate = useNavigate()
  const [username, set_username] = useState('')
  const [password, set_password] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const SubmitLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!username) throw new Error('Please input your username.')
    if (!password) throw new Error('Please input proper password')

    try {
      setIsLoading(true)
      const response = await fetch(`/api/cms/login`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      })

      const res = await response.json()
      if (!response.ok) throw new Error(res.message)
      localStorage.setItem('accessToken', res.data)

      setIsLoading(false)

      navigate(navigationPath.dashboard)
    } catch (error: any) {
      setIsLoading(false)
      alert(error.message || `Terjadi kesalahan ketika login.`)
    }
  }

  return (
    <div
      className={`bg-primary min-h-screen font-poppins  flex justify-center items-center`}
    >
      {isLoading ? (
        <SpinningLoader />
      ) : (
        <div className='min-h-full flex flex-col justify-center py-24 sm:px-6 lg:px-8 '>
          <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
            <div className='text-3xl font-link2 w-96 pl-2 my-4 mx-20 '>
              <MdSettings className='ml-16 text-md w-36 h-36 text-secondary' />
              <h1 className='text-secondary'>{`APP WRAPPER CMS`}</h1>
            </div>
            <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
              <form className='space-y-6' onSubmit={SubmitLogin}>
                <div>
                  <label
                    htmlFor='username'
                    className='block text-sm font-poppins text-secondary'
                  >
                    Username
                  </label>
                  <div className='mt-1'>
                    <input
                      required
                      type='text'
                      id='username'
                      name='username'
                      placeholder='Masukkan Username '
                      value={username}
                      onChange={event => set_username(event.target.value)}
                      className='placeholder-bg-yellow block w-full px-3 py-2 border border-grey-300 rounded-md shadow-sm  focus:outline-none focus:ring-secondary focus:border-secondary'
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor='password'
                    className='block text-sm font-poppins text-secondary'
                  >
                    Password
                  </label>
                  <div className='mt-1'>
                    <input
                      required
                      type='password'
                      id='password'
                      name='password'
                      placeholder='Masukkan Password'
                      value={password}
                      onChange={event => set_password(event.target.value)}
                      className='placeholder-bg-yellow block w-full px-3 py-2 border border-grey-300 rounded-md shadow-sm  focus:outline-none focus:ring-secondary focus:border-secondary'
                    />
                  </div>
                </div>
                <div>
                  <button
                    type='submit'
                    className='w-1/4 flex justify-center ml-36 py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium active:bg-green-500 text-white bg-secondary hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary'
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Login
