import { useEffect, useState } from 'react';
import NormalTextfield from '../../Components/Textfields/NormalTextfield';
import FloatingSaveButton from './Components/floating-save-button';

const Discount = () => {
  const [id, setId] = useState('');
  const [diskon4D, setDiskon4D] = useState('');
  const [diskon3D, setDiskon3D] = useState('');
  const [diskon2D, setDiskon2D] = useState('');
  const [diskonColokBebas, setDiskonColokBebas] = useState('');
  const [diskonColokBebas2D, setDiskonColokBebas2D] = useState('');
  const [diskonColokNaga, setDiskonColokNaga] = useState('');
  const [diskonColokJitu, setDiskonColokJitu] = useState('');
  const [diskonShio, setDiskonShio] = useState('');
  const [diskonKombinasi, setDiskonKombinasi] = useState('');
  const [diskonTengahTepi, setDiskonTengahTepi] = useState('');
  const [diskonDasar, setDiskonDasar] = useState('');
  const [diskon5050, setDiskon5050] = useState('');
  const [diskonSilangHomo, setDiskonSilangHomo] = useState('');
  const [diskonKembangKempis, setDiskonKembangKempis] = useState('');
  const [diskonMacauShio, setDiskonMacauShio] = useState('');
  const [diskon4DMacau, setDiskon4DMacau] = useState('');
  const [diskon3DMacau, setDiskon3DMacau] = useState('');
  const [diskon2DMacau, setDiskon2DMacau] = useState('');
  const [superdiskon4DMacau, setSuperDiskon4DMacau] = useState('');
  const [superdiskon3DMacau, setSuperDiskon3DMacau] = useState('');
  const [superdiskon2DMacau, setSuperDiskon2DMacau] = useState('');
  const [diskonColokBebasMacau, setDiskonColokBebasMacau] = useState('');
  const [diskonColokBebas2DMacau, setDiskonColokBebas2DMacau] = useState('');
  const [diskonColokNagaMacau, setDiskonColokNagaMacau] = useState('');
  const [diskonColokJituMacau, setDiskonColokJituMacau] = useState('');
  const [diskonShioMacau, setDiskonShioMacau] = useState('');
  const [diskonKombinasiMacau, setDiskonKombinasiMacau] = useState('');
  const [diskonTengahTepiMacau, setDiskonTengahTepiMacau] = useState('');
  const [diskonDasarMacau, setDiskonDasarMacau] = useState('');
  const [diskon5050Macau, setDiskon5050Macau] = useState('');
  const [diskonSilangHomoMacau, setDiskonSilangHomoMacau] = useState('');
  const [diskonKembangKempisMacau, setDiskonKembangKempisMacau] = useState('');
  const [diskonMacauShioMacau, setDiskonMacauShioMacau] = useState('');

 

  useEffect(() => {
    fetch(`/api/cms/get-data-discount`)
      .then(response => response.json())
      .then(res => {
        setId(res.data.id);
        setDiskon4D(res.data.diskon4D);
        setDiskon3D(res.data.diskon3D);
        setDiskon2D(res.data.diskon2D);
        setDiskonColokBebas(res.data.diskonColokBebas);
        setDiskonColokBebas2D(res.data.diskonColokBebas2D);
        setDiskonColokNaga(res.data.diskonColokNaga);
        setDiskonColokJitu(res.data.diskonColokJitu);
        setDiskonShio(res.data.diskonShio);
        setDiskonKombinasi(res.data.diskonKombinasi);
        setDiskonTengahTepi(res.data.diskonTengahTepi);
        setDiskonDasar(res.data.diskonDasar);
        setDiskon5050(res.data.diskon5050);
        setDiskonSilangHomo(res.data.diskonSilangHomo);
        setDiskonKembangKempis(res.data.diskonKembangKempis);
        setDiskonMacauShio(res.data.diskonMacauShio);
        setDiskon4DMacau(res.data.diskon4DMacau);
        setDiskon3DMacau(res.data.diskon3DMacau);
        setDiskon2DMacau(res.data.diskon2DMacau);
        setSuperDiskon4DMacau(res.data.superdiskon4DMacau);
        setSuperDiskon3DMacau(res.data.superdiskon3DMacau);
        setSuperDiskon2DMacau(res.data.superdiskon2DMacau);
        setDiskonColokBebasMacau(res.data.diskonColokBebasMacau);
        setDiskonColokBebas2DMacau(res.data.diskonColokBebas2DMacau);
        setDiskonColokNagaMacau(res.data.diskonColokNagaMacau);
        setDiskonColokJituMacau(res.data.diskonColokJituMacau);
        setDiskonShioMacau(res.data.diskonShioMacau);
        setDiskonKombinasiMacau(res.data.diskonKombinasiMacau);
        setDiskonTengahTepiMacau(res.data.diskonTengahTepiMacau);
        setDiskonDasarMacau(res.data.diskonDasarMacau);
        setDiskon5050Macau(res.data.diskon5050Macau);
        setDiskonSilangHomoMacau(res.data.diskonSilangHomoMacau);
        setDiskonKembangKempisMacau(res.data.diskonKembangKempisMacau);
        setDiskonMacauShioMacau(res.data.diskonMacauShioMacau);
      });
  }, []);

  const save = async () => {
    try {
      const response = await fetch(`/api/cms/update-discount`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          diskon4D,
          diskon3D,
          diskon2D,
          diskonColokBebas,
          diskonColokBebas2D,
          diskonColokNaga,
          diskonColokJitu,
          diskonShio,
          diskonKombinasi,
          diskonTengahTepi,
          diskonDasar,
          diskon5050,
          diskonSilangHomo,
          diskonKembangKempis,
          diskonMacauShio,
          diskon4DMacau,
          diskon3DMacau,
          diskon2DMacau,
          superdiskon4DMacau,
          superdiskon3DMacau,
          superdiskon2DMacau,
          diskonColokBebasMacau,
          diskonColokBebas2DMacau,
          diskonColokNagaMacau,
          diskonColokJituMacau,
          diskonShioMacau,
          diskonKombinasiMacau,
          diskonTengahTepiMacau,
          diskonDasarMacau,
          diskon5050Macau,
          diskonSilangHomoMacau,
          diskonKembangKempisMacau,
          diskonMacauShioMacau,
        }),
      });
      console.log(response.body);
      const res = await response.json();
      if (!response.ok) throw new Error(res.errors[0].message || res.message);
      alert('Berhasil Update');

      // navigate(navigationPath.new);
    } catch (error: any) {
      alert(error.message || `Terjadi kesalahan ketika menyimpan data discount.`);
    }
  };

  return (
    <div className='flex-1 bg-secondary'>
      <div className='flex items-center justify-center pt-6 px-8'>
        <div className='mx-auto w-full max-w-[550px]'>
          <FloatingSaveButton onClick={save} />

          <NormalTextfield title={'Diskon 4D'} value={diskon4D} onChange={setDiskon4D} />
          <NormalTextfield title={'Diskon 3D'} value={diskon3D} onChange={setDiskon3D} />
          <NormalTextfield title={'Diskon 2D'} value={diskon2D} onChange={setDiskon2D} />
          <NormalTextfield title={'Diskon Colok Bebas'} value={diskonColokBebas} onChange={setDiskonColokBebas} />
          <NormalTextfield title={'Diskon Colok Bebas 2D'} value={diskonColokBebas2D} onChange={setDiskonColokBebas2D} />
          <NormalTextfield title={'Diskon Colok Naga'} value={diskonColokNaga} onChange={setDiskonColokNaga} />
          <NormalTextfield title={'Diskon Colok Jitu'} value={diskonColokJitu} onChange={setDiskonColokJitu} />
          <NormalTextfield title={'Diskon SHIO'} value={diskonShio} onChange={setDiskonShio} />
          <NormalTextfield title={'Diskon Kombinasi'} value={diskonKombinasi} onChange={setDiskonKombinasi} />
          <NormalTextfield title={'Diskon Tengah Tepi'} value={diskonTengahTepi} onChange={setDiskonTengahTepi} />
          <NormalTextfield title={'Diskon Dasar'} value={diskonDasar} onChange={setDiskonDasar} />
          <NormalTextfield title={'Diskon 5050'} value={diskon5050} onChange={setDiskon5050} />
          <NormalTextfield title={'Diskon Silang Homo'} value={diskonSilangHomo} onChange={setDiskonSilangHomo} />
          <NormalTextfield title={'Diskon Kembang Kempis'} value={diskonKembangKempis} onChange={setDiskonKembangKempis} />
          <NormalTextfield title={'Diskon Macau Shio'} value={diskonMacauShio} onChange={setDiskonMacauShio} />
        </div>
        <div className='mx-auto w-full max-w-[550px] ml-3'>
          <NormalTextfield title={'Diskon 4D TOTO MACAU'} value={diskon4DMacau} onChange={setDiskon4DMacau} />
          <NormalTextfield title={'Diskon 3D TOTO MACAU'} value={diskon3DMacau} onChange={setDiskon3DMacau} />
          <NormalTextfield title={'Diskon 2D TOTO MACAU'} value={diskon2DMacau} onChange={setDiskon2DMacau} />

          <NormalTextfield
            title={'Diskon Colok Bebas TOTO MACAU'}
            value={diskonColokBebasMacau}
            onChange={setDiskonColokBebasMacau}
          />
          <NormalTextfield
            title={'Diskon Colok Bebas 2D TOTO MACAU'}
            value={diskonColokBebas2DMacau}
            onChange={setDiskonColokBebas2DMacau}
          />
          <NormalTextfield
            title={'Diskon Colok Naga TOTO MACAU'}
            value={diskonColokNagaMacau}
            onChange={setDiskonColokNagaMacau}
          />
          <NormalTextfield
            title={'Diskon Colok Jitu TOTO MACAU'}
            value={diskonColokJituMacau}
            onChange={setDiskonColokJituMacau}
          />
          <NormalTextfield title={'Diskon SHIO TOTO MACAU'} value={diskonShioMacau} onChange={setDiskonShioMacau} />
          <NormalTextfield
            title={'Diskon Kombinasi TOTO MACAU'}
            value={diskonKombinasiMacau}
            onChange={setDiskonKombinasiMacau}
          />
          <NormalTextfield
            title={'Diskon Tengah Tepi TOTO MACAU'}
            value={diskonTengahTepiMacau}
            onChange={setDiskonTengahTepiMacau}
          />
          <NormalTextfield title={'Diskon Dasar TOTO MACAU'} value={diskonDasarMacau} onChange={setDiskonDasarMacau} />
          <NormalTextfield title={'Diskon 5050 TOTO MACAU'} value={diskon5050Macau} onChange={setDiskon5050Macau} />
          <NormalTextfield
            title={'Diskon Silang Homo TOTO MACAU'}
            value={diskonSilangHomoMacau}
            onChange={setDiskonSilangHomoMacau}
          />
          <NormalTextfield
            title={'Diskon Kembang Kempis TOTO MACAU'}
            value={diskonKembangKempisMacau}
            onChange={setDiskonKembangKempisMacau}
          />
          <NormalTextfield
            title={'Diskon Macau Shio TOTO MACAU'}
            value={diskonMacauShioMacau}
            onChange={setDiskonMacauShioMacau}
          />
        </div>
      </div>
      <div className='flex items-center justify-center pt-6 px-8'>
        <div className='mx-auto w-full max-w-[550px]'>
          <NormalTextfield title={'SUPER Diskon 4D TOTO MACAU'} value={superdiskon4DMacau} onChange={setSuperDiskon4DMacau} />
          <NormalTextfield title={'SUPER Diskon 3D TOTO MACAU'} value={superdiskon3DMacau} onChange={setSuperDiskon3DMacau} />
          <NormalTextfield title={'SUPER Diskon 2D TOTO MACAU'} value={superdiskon2DMacau} onChange={setSuperDiskon2DMacau} />
        </div>
      </div>
    </div>
  );
};

export default Discount;
