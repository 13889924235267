import React from 'react';

interface NormalTextfieldProps {
  title: string;
  value: string;
  onChange: (value: string) => void;
}

const NormalTextfield: React.FC<NormalTextfieldProps> = ({ title, value, onChange }) => {
  return (
    <div className='mb-5'>
      <label className='mb-3 block text-base font-medium text-[#07074D]'>{title}</label>
      <input
        className='w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md'
        type='text'
        placeholder='url'
        value={value}
        onChange={(event) => onChange(event.target.value)}
      />
    </div>
  );
};

export default NormalTextfield;
