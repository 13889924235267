import { useEffect, useState } from 'react';
import SlotDemoTextfield from './Components/slot-demo-textfield';
import FloatingSaveButton from './Components/floating-save-button';

const SlotDemo = () => {
  const [id, setId] = useState('');
  const [linkDemoOlympus, setLinkDemoOlympus] = useState('');
  const [linkDemoStarlightPrincess, setLinkDemoStarlightPrincess] = useState('');
  const [linkDemoAztecGames, setLinkDemoAztecGames] = useState('');
  const [linkDemoSweetBonanza, setLinkDemoSweetBonanza] = useState('');
  const [linkDemoWildWestGold, setLinkDemoWildWestGold] = useState('');
  const [linkDemoBonanzaXmas, setLinkDemoBonanzaXmas] = useState('');
  const [linkDemoGatotKaca, setLinkDemoGatotKaca] = useState('');
  const [linkDemoBarnFestival, setLinkDemoBarnFestival] = useState('');
  const [linkDemoSpaceman, setLinkDemoSpaceman] = useState('');
  const [linkDemo5Lions, setLinkDemo5Lions] = useState('');
  const [linkDemoBonanzaGold, setLinkDemoBonanzaGold] = useState('');
  const [linkDemoFruitParty, setLinkDemoFruitParty] = useState('');
  const [linkDemoPyramidBonanza, setLinkDemoPyramidBonanza] = useState('');
  const [linkDemoMidas, setLinkDemoMidas] = useState('');
  const [linkDemoJokerJewel, setLinkDemoJokerJewel] = useState('');
  const [linkDemoThor, setLinkDemoThor] = useState('');
  const [linkDemoWildBeachParty, setLinkDemoWildBeachParty] = useState('');
  const [linkDemoFruitParty2, setLinkDemoFruitParty2] = useState('');
  const [linkDemoFireStrike, setLinkDemoFireStrike] = useState('');
  const [linkDemoSuperX, setLinkDemoSuperX] = useState('');
  const [linkDemoZombieCarnival, setLinkDemoZombieCarnival] = useState('');
  const [linkDemo888Gold, setLinkDemo888Gold] = useState('');

  //*  State Banner Slot Demo
  const [linkBannerDemoOlympus, setLinkBannerDemoOlympus] = useState('');
  const [linkBannerDemoStarlightPrincess, setLinkBannerDemoStarlightPrincess] = useState('');
  const [linkBannerDemoAztecGames, setLinkBannerDemoAztecGames] = useState('');
  const [linkBannerDemoSweetBonanza, setLinkBannerDemoSweetBonanza] = useState('');
  const [linkBannerDemoWildWestGold, setLinkBannerDemoWildWestGold] = useState('');
  const [linkBannerDemoBonanzaXmas, setLinkBannerDemoBonanzaXmas] = useState('');
  const [linkBannerDemoGatotKaca, setLinkBannerDemoGatotKaca] = useState('');
  const [linkBannerDemoBarnFestival, setLinkBannerDemoBarnFestival] = useState('');
  const [linkBannerDemoSpaceman, setLinkBannerDemoSpaceman] = useState('');
  const [linkBannerDemo5Lions, setLinkBannerDemo5Lions] = useState('');
  const [linkBannerDemoBonanzaGold, setLinkBannerDemoBonanzaGold] = useState('');
  const [linkBannerDemoFruitParty, setLinkBannerDemoFruitParty] = useState('');
  const [linkBannerDemoPyramidBonanza, setLinkBannerDemoPyramidBonanza] = useState('');
  const [linkBannerDemoMidas, setLinkBannerDemoMidas] = useState('');
  const [linkBannerDemoJokerJewel, setLinkBannerDemoJokerJewel] = useState('');
  const [linkBannerDemoThor, setLinkBannerDemoThor] = useState('');
  const [linkBannerDemoWildBeachParty, setLinkBannerDemoWildBeachParty] = useState('');
  const [linkBannerDemoFruitParty2, setLinkBannerDemoFruitParty2] = useState('');
  const [linkBannerDemoFireStrike, setLinkBannerDemoFireStrike] = useState('');
  const [linkBannerDemoSuperX, setLinkBannerDemoSuperX] = useState('');
  const [linkBannerDemoZombieCarnival, setLinkBannerDemoZombieCarnival] = useState('');
  const [linkBannerDemo888Gold, setLinkBannerDemo888Gold] = useState('');

  

  useEffect(() => {
    fetch(`/api/cms/get-data-slot-demo`)
      .then(response => response.json())
      .then(res => {
        setId(res.data.id);
        setLinkDemoOlympus(res.data.linkDemoOlympus);
        setLinkDemoStarlightPrincess(res.data.linkDemoStarlightPrincess);
        setLinkDemoAztecGames(res.data.linkDemoAztecGames);
        setLinkDemoSweetBonanza(res.data.linkDemoSweetBonanza);
        setLinkDemoWildWestGold(res.data.linkDemoWildWestGold);
        setLinkDemoBonanzaXmas(res.data.linkDemoBonanzaXmas);
        setLinkDemoGatotKaca(res.data.linkDemoGatotKaca);
        setLinkDemoBarnFestival(res.data.linkDemoBarnFestival);
        setLinkDemoSpaceman(res.data.linkDemoSpaceman);
        setLinkDemo5Lions(res.data.linkDemo5Lions);
        setLinkDemoBonanzaGold(res.data.linkDemoBonanzaGold);
        setLinkDemoFruitParty(res.data.linkDemoFruitParty);
        setLinkDemoPyramidBonanza(res.data.linkDemoPyramidBonanza);
        setLinkDemoMidas(res.data.linkDemoMidas);
        setLinkDemoJokerJewel(res.data.linkDemoJokerJewel);
        setLinkDemoThor(res.data.linkDemoThor);
        setLinkDemoWildBeachParty(res.data.linkDemoWildBeachParty);
        setLinkDemoFruitParty2(res.data.linkDemoFruitParty2);
        setLinkDemoFireStrike(res.data.linkDemoFireStrike);
        setLinkDemoSuperX(res.data.linkDemoSuperX);
        setLinkDemoZombieCarnival(res.data.linkDemoZombieCarnival);
        setLinkDemo888Gold(res.data.linkDemo888Gold);
        setLinkBannerDemoOlympus(res.data.linkBannerDemoOlympus);
        setLinkBannerDemoStarlightPrincess(res.data.linkBannerDemoStarlightPrincess);
        setLinkBannerDemoAztecGames(res.data.linkBannerDemoAztecGames);
        setLinkBannerDemoSweetBonanza(res.data.linkBannerDemoSweetBonanza);
        setLinkBannerDemoWildWestGold(res.data.linkBannerDemoWildWestGold);
        setLinkBannerDemoBonanzaXmas(res.data.linkBannerDemoBonanzaXmas);
        setLinkBannerDemoGatotKaca(res.data.linkBannerDemoGatotKaca);
        setLinkBannerDemoBarnFestival(res.data.linkBannerDemoBarnFestival);
        setLinkBannerDemoSpaceman(res.data.linkBannerDemoSpaceman);
        setLinkBannerDemo5Lions(res.data.linkBannerDemo5Lions);
        setLinkBannerDemoBonanzaGold(res.data.linkBannerDemoBonanzaGold);
        setLinkBannerDemoFruitParty(res.data.linkBannerDemoFruitParty);
        setLinkBannerDemoPyramidBonanza(res.data.linkBannerDemoPyramidBonanza);
        setLinkBannerDemoMidas(res.data.linkBannerDemoMidas);
        setLinkBannerDemoJokerJewel(res.data.linkBannerDemoJokerJewel);
        setLinkBannerDemoThor(res.data.linkBannerDemoThor);
        setLinkBannerDemoWildBeachParty(res.data.linkBannerDemoWildBeachParty);
        setLinkBannerDemoFruitParty2(res.data.linkBannerDemoFruitParty2);
        setLinkBannerDemoFireStrike(res.data.linkBannerDemoFireStrike);
        setLinkBannerDemoSuperX(res.data.linkBannerDemoSuperX);
        setLinkBannerDemoZombieCarnival(res.data.linkBannerDemoZombieCarnival);
        setLinkBannerDemo888Gold(res.data.linkBannerDemo888Gold);
      });
  }, []);

  const save = async () => {
    try {
      const response = await fetch(`/api/cms/update-slot-demo`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          linkDemoOlympus,
          linkDemoStarlightPrincess,
          linkDemoAztecGames,
          linkDemoSweetBonanza,
          linkDemoWildWestGold,
          linkDemoBonanzaXmas,
          linkDemoGatotKaca,
          linkDemoBarnFestival,
          linkDemoSpaceman,
          linkDemo5Lions,
          linkDemoBonanzaGold,
          linkDemoFruitParty,
          linkDemoPyramidBonanza,
          linkDemoMidas,
          linkDemoJokerJewel,
          linkDemoThor,
          linkDemoWildBeachParty,
          linkDemoFruitParty2,
          linkDemoFireStrike,
          linkDemoSuperX,
          linkDemoZombieCarnival,
          linkDemo888Gold,
          linkBannerDemoOlympus,
          linkBannerDemoStarlightPrincess,
          linkBannerDemoAztecGames,
          linkBannerDemoSweetBonanza,
          linkBannerDemoWildWestGold,
          linkBannerDemoBonanzaXmas,
          linkBannerDemoGatotKaca,
          linkBannerDemoBarnFestival,
          linkBannerDemoSpaceman,
          linkBannerDemo5Lions,
          linkBannerDemoBonanzaGold,
          linkBannerDemoFruitParty,
          linkBannerDemoPyramidBonanza,
          linkBannerDemoMidas,
          linkBannerDemoJokerJewel,
          linkBannerDemoThor,
          linkBannerDemoWildBeachParty,
          linkBannerDemoFruitParty2,
          linkBannerDemoFireStrike,
          linkBannerDemoSuperX,
          linkBannerDemoZombieCarnival,
          linkBannerDemo888Gold,
        }),
      });
      const res = await response.json();
      console.log(res);
      if (!response.ok) throw new Error(res.errors[0].message || res.message);
      alert('Berhasil Update');

      // navigate(navigationPath.new);
    } catch (error: any) {
      alert(error.message || `Terjadi kesalahan ketika menyimpan data slot demo.`);
    }
  };

  return (
    <div className='flex flex-col'>
      <FloatingSaveButton onClick={save} />
      <SlotDemoTextfield
        game='Gates of Olympus'
        bannerLink={linkBannerDemoOlympus}
        demoLink={linkDemoOlympus}
        bannerLinkOnChange={setLinkBannerDemoOlympus}
        demoLinkOnChange={setLinkDemoOlympus}
      />
      <SlotDemoTextfield
        game='Starlight Princess'
        bannerLink={linkBannerDemoStarlightPrincess}
        demoLink={linkDemoStarlightPrincess}
        bannerLinkOnChange={setLinkBannerDemoStarlightPrincess}
        demoLinkOnChange={setLinkDemoStarlightPrincess}
      />
      <SlotDemoTextfield
        game='Aztec Games'
        bannerLink={linkBannerDemoAztecGames}
        demoLink={linkDemoAztecGames}
        bannerLinkOnChange={setLinkBannerDemoAztecGames}
        demoLinkOnChange={setLinkDemoAztecGames}
      />
      <SlotDemoTextfield
        game='Sweet Bonanza'
        bannerLink={linkBannerDemoSweetBonanza}
        demoLink={linkDemoSweetBonanza}
        bannerLinkOnChange={setLinkBannerDemoSweetBonanza}
        demoLinkOnChange={setLinkDemoSweetBonanza}
      />
      <SlotDemoTextfield
        game='Wild West Gold'
        bannerLink={linkBannerDemoWildWestGold}
        demoLink={linkDemoWildWestGold}
        bannerLinkOnChange={setLinkBannerDemoWildWestGold}
        demoLinkOnChange={setLinkDemoWildWestGold}
      />
      <SlotDemoTextfield
        game='Bonanza Xmas'
        bannerLink={linkBannerDemoBonanzaXmas}
        demoLink={linkDemoBonanzaXmas}
        bannerLinkOnChange={setLinkBannerDemoBonanzaXmas}
        demoLinkOnChange={setLinkDemoBonanzaXmas}
      />
      <SlotDemoTextfield
        game='Gates of Gatot Kaca'
        bannerLink={linkBannerDemoGatotKaca}
        demoLink={linkDemoGatotKaca}
        bannerLinkOnChange={setLinkBannerDemoGatotKaca}
        demoLinkOnChange={setLinkDemoGatotKaca}
      />
      <SlotDemoTextfield
        game='Barn Festival'
        bannerLink={linkBannerDemoBarnFestival}
        demoLink={linkDemoBarnFestival}
        bannerLinkOnChange={setLinkBannerDemoBarnFestival}
        demoLinkOnChange={setLinkDemoBarnFestival}
      />
      <SlotDemoTextfield
        game='Spaceman'
        bannerLink={linkBannerDemoSpaceman}
        demoLink={linkDemoSpaceman}
        bannerLinkOnChange={setLinkBannerDemoSpaceman}
        demoLinkOnChange={setLinkDemoSpaceman}
      />
      <SlotDemoTextfield
        game='Lions Megaways'
        bannerLink={linkBannerDemo5Lions}
        demoLink={linkDemo5Lions}
        bannerLinkOnChange={setLinkBannerDemo5Lions}
        demoLinkOnChange={setLinkDemo5Lions}
      />
      <SlotDemoTextfield
        game='Bonanza Gold'
        bannerLink={linkBannerDemoBonanzaGold}
        demoLink={linkDemoBonanzaGold}
        bannerLinkOnChange={setLinkBannerDemoBonanzaGold}
        demoLinkOnChange={setLinkDemoBonanzaGold}
      />
      <SlotDemoTextfield
        game='Fruit Party'
        bannerLink={linkBannerDemoFruitParty}
        demoLink={linkDemoFruitParty}
        bannerLinkOnChange={setLinkBannerDemoFruitParty}
        demoLinkOnChange={setLinkDemoFruitParty}
      />
      <SlotDemoTextfield
        game='Pyramid Bonanza'
        bannerLink={linkBannerDemoPyramidBonanza}
        demoLink={linkDemoPyramidBonanza}
        bannerLinkOnChange={setLinkBannerDemoPyramidBonanza}
        demoLinkOnChange={setLinkDemoPyramidBonanza}
      />
      <SlotDemoTextfield
        game='The Hand of Midas'
        bannerLink={linkBannerDemoMidas}
        demoLink={linkDemoMidas}
        bannerLinkOnChange={setLinkBannerDemoMidas}
        demoLinkOnChange={setLinkDemoMidas}
      />
      <SlotDemoTextfield
        game='Joker Jewels'
        bannerLink={linkBannerDemoJokerJewel}
        demoLink={linkDemoJokerJewel}
        bannerLinkOnChange={setLinkBannerDemoJokerJewel}
        demoLinkOnChange={setLinkDemoJokerJewel}
      />
      <SlotDemoTextfield
        game='Power of Thor'
        bannerLink={linkBannerDemoThor}
        demoLink={linkDemoThor}
        bannerLinkOnChange={setLinkBannerDemoThor}
        demoLinkOnChange={setLinkDemoThor}
      />
      <SlotDemoTextfield
        game='Wild Beach Party'
        bannerLink={linkBannerDemoWildBeachParty}
        demoLink={linkDemoWildBeachParty}
        bannerLinkOnChange={setLinkBannerDemoWildBeachParty}
        demoLinkOnChange={setLinkDemoWildBeachParty}
      />
      <SlotDemoTextfield
        game='Fruit Party 2'
        bannerLink={linkBannerDemoFruitParty2}
        demoLink={linkDemoFruitParty2}
        bannerLinkOnChange={setLinkBannerDemoFruitParty2}
        demoLinkOnChange={setLinkDemoFruitParty2}
      />
      <SlotDemoTextfield
        game='Fire Strike'
        bannerLink={linkBannerDemoFireStrike}
        demoLink={linkDemoFireStrike}
        bannerLinkOnChange={setLinkBannerDemoFireStrike}
        demoLinkOnChange={setLinkDemoFireStrike}
      />
      <SlotDemoTextfield
        game='Super X'
        bannerLink={linkBannerDemoSuperX}
        demoLink={linkDemoSuperX}
        bannerLinkOnChange={setLinkBannerDemoSuperX}
        demoLinkOnChange={setLinkDemoSuperX}
      />
      <SlotDemoTextfield
        game='Zombie Carnival'
        bannerLink={linkBannerDemoZombieCarnival}
        demoLink={linkDemoZombieCarnival}
        bannerLinkOnChange={setLinkBannerDemoZombieCarnival}
        demoLinkOnChange={setLinkDemoZombieCarnival}
      />
      <SlotDemoTextfield
        game='888 Gold'
        bannerLink={linkBannerDemo888Gold}
        demoLink={linkDemo888Gold}
        bannerLinkOnChange={setLinkBannerDemo888Gold}
        demoLinkOnChange={setLinkDemo888Gold}
      />
    </div>
  );
};

export default SlotDemo;
