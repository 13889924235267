import React from 'react';

interface SlotDemoTextfieldProps {
  game: string;
  bannerLink: string;
  demoLink: string;
  bannerLinkOnChange: (value: string) => void;
  demoLinkOnChange: (value: string) => void;
}

const SlotDemoTextfield: React.FC<SlotDemoTextfieldProps> = ({
  game,
  bannerLink,
  demoLink,
  bannerLinkOnChange,
  demoLinkOnChange,
}) => {
  return (
    <div className='flex '>
      <div className='w-1/2 border-r border-gray-300 pr-4'>
        <div className='flex items-center mb-5'>
          <label className='mr-3 text-base font-medium text-[#07074D]'>{game}</label>
          <input
            className='flex-grow rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md'
            type='text'
            placeholder='url'
            value={bannerLink}
            onChange={event => bannerLinkOnChange(event.target.value)}
          />
        </div>
      </div>
      <hr className='flex-grow border-t border-gray-300'></hr>
      <div className='w-1/2 pl-4'>
        <div className='flex items-center mb-5'>
          <input
            className='flex-grow rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md'
            type='text'
            placeholder='url'
            value={demoLink}
            onChange={event => demoLinkOnChange(event.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default SlotDemoTextfield;
