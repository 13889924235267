// import { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { navigationPath } from '../App';

const SpinningLoader = () => {
  // const navigate = useNavigate();

  // useEffect(() => {
  //   const currentUser = async () => {
  //     try {
  //       const uri = '/api/cms/is-token-valid';
  //       const response = await fetch(uri);
  //       const res = await response.json();
  //       if (!response.ok) throw new Error(res.message);
  //       if (res.isTokenValid) navigate(navigationPath.dashboard);
  //       else if (!res.isTokenValid) navigate(navigationPath.login);
  //     } catch (error: any) {
  //       alert(error.message || `Terjadi kesalahan ketika mengecek token.`);
  //     }
  //   };

  //   currentUser();
  // }, [navigate]);

  return <div className='spinning-loader'></div>;
};

export default SpinningLoader;
