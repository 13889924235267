import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { navigationPath } from '../App'
import errorMessage from '../Helper/error-message'
import SpinningLoader from '../Components/SpinningLoading'

const Loading: React.FC = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const checkToken = async () => {
      try {
        const uri = '/api/cms/is-token-valid'
        const response = await fetch(uri)
        const res = await response.json()
        if (!response.ok) throw new Error(res.message)
        if (res.isTokenValid) navigate(navigationPath.dashboard)
        else if (!res.isTokenValid) navigate(navigationPath.login)
      } catch (error: any) {
        alert(
          errorMessage(error.response?.data || error) ||
            `Terjadi kesalahan ketika mengecek token...`
        )
      }
    }
    checkToken()
  }, [navigate])

  return (
    <>
      <Helmet>
        <title>Loading - CMS</title>
      </Helmet>
      <div className='min-h-screen flex justify-center items-center'>
        Loading...
        <SpinningLoader />
      </div>
      ;
    </>
  )
}

export default Loading
