import { Layout, Menu } from 'antd'
import { Content, Footer, Header } from 'antd/es/layout/layout'
import { useEffect, useState } from 'react'
import SlotDemo from './Slot_Demo'
import Home from './Home'
import Discount from './Discount'
import { useNavigate } from 'react-router-dom'
import { navigationPath } from '../../App'
import errorMessage from '../../Helper/error-message'
import Referral from './Referral'

const Dashboard = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const checkToken = async () => {
      try {
        const uri = '/api/cms/is-token-valid'
        const response = await fetch(uri)
        const res = await response.json()
        if (!response.ok) throw new Error(res.message)
        if (res.isTokenValid) navigate(navigationPath.dashboard)
        else if (!res.isTokenValid) navigate(navigationPath.login)
      } catch (error: any) {
        alert(
          errorMessage(error.response?.data || error) ||
            `Terjadi kesalahan ketika mengecek token...`
        )
      }
    }
    checkToken()
  }, [navigate])

  const [namaWeb, setNamaWeb] = useState('')
  const [selectedMenu, setSelectedMenu] = useState('1')
  const [fetching] = useState(false)

  const menuItems = [
    { key: '1', label: 'Home' },
    { key: '2', label: 'Slot Demo' },
    { key: '3', label: 'Discount' },
    { key: '4', label: 'Referral' },
  ]

  const [showChangelog, setShowChangelog] = useState(false)
  const [showErrorLog, setShowErrorLog] = useState(false)
  const [errorLogs, setErrorLogs] = useState([])
  const [changeLogs, setChangeLogs] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [dataPerPage] = useState(20)

  const indexOfLastData = currentPage * dataPerPage
  const indexOfFirstData = indexOfLastData - dataPerPage
  const currentData = errorLogs.slice(indexOfFirstData, indexOfLastData)
  const changeLogsSliced = changeLogs.slice(indexOfFirstData, indexOfLastData)

  useEffect(() => {
    fetch(`/api/cms/get-data-home`)
      .then(response => response.json())
      .then(res => {
        setNamaWeb(res.namaWeb)
      })
  }, [fetching])

  const logOutHandler = async () => {
    try {
      const uri = '/api/cms/logout-cms'
      const response = await fetch(uri)
      const res = await response.json()
      if (!response.ok) throw new Error(res.message)
    } catch (error: any) {
      alert(
        errorMessage(error.response?.data || error) ||
          `Terjadi kesalahan ketika logout dari server...`
      )
    }
    alert('Anda telah Log Out')
    navigate(navigationPath.logout)
    localStorage.removeItem('accessToken')
  }

  const fetchErrorLogs = async () => {
    try {
      const response = await fetch(`/api/cms/get-error-message`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      const res = await response.json()
      if (!response.ok) throw new Error(res.errors[0].message || res.message)
      setErrorLogs(res.data)

      navigate(navigationPath.dashboard)
    } catch (error: any) {
      alert(error.message || `Terjadi kesalahan ketika fetch.`)
    }
  }

  const fetchChangeLogs = async () => {
    try {
      const response = await fetch(`/api/cms/get-changelog-message`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      const res = await response.json()
      if (!response.ok) throw new Error(res.errors[0].message || res.message)
      setChangeLogs(res.data)

      navigate(navigationPath.dashboard)
    } catch (error: any) {
      alert(error.message || `Terjadi kesalahan ketika fetch.`)
    }
  }

  const handlePrevButton = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleErrorLogsNextButton = () => {
    if (currentPage < Math.ceil(errorLogs.length / dataPerPage)) {
      setCurrentPage(currentPage + 1)
    }
  }

  const handleChangeLogsNextButton = () => {
    if (currentPage < Math.ceil(changeLogs.length / dataPerPage)) {
      setCurrentPage(currentPage + 1)
    }
  }

  const handleErrorLogsDelete = async () => {
    if (window.confirm('Hapus semua error log?')) {
      try {
        const response = await fetch(`/api/cms/delete-error-message`, {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        const res = await response.json()
        if (!response.ok) throw new Error(res.errors[0].message || res.message)
        setShowErrorLog(!showErrorLog)

        navigate(navigationPath.dashboard)
      } catch (error: any) {
        alert(error.message || `Terjadi kesalahan ketika menghapus logs.`)
      }
    }
  }

  const handleChangeLogsDelete = async () => {
    if (window.confirm('Hapus semua changelog?')) {
      try {
        const response = await fetch(`/api/cms/delete-changelog-message`, {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        const res = await response.json()
        if (!response.ok) throw new Error(res.errors[0].message || res.message)
        setShowChangelog(!showChangelog)

        navigate(navigationPath.dashboard)
      } catch (error: any) {
        alert(error.message || `Terjadi kesalahan ketika menghapus logs.`)
      }
    }
  }

  return (
    <Layout>
      <Header className='sticky top-0 z-10 w-full flex items-center py-10'>
        <h1 className=' text-white text-lg font-bold pr-10 hidden xl:inline-block'>{`APP ANTI NAWALA CMS - ${namaWeb}`}</h1>
        <Menu
          theme='dark'
          mode='horizontal'
          selectedKeys={[selectedMenu]}
          onSelect={({ key }) => setSelectedMenu(key)}
          items={menuItems}
          className='w-[400px]'
        />

        <div className='ml-auto flex justify-evenly'>
          <button
            className='bg-red-600 text-white py-1 px-4 rounded font-medium hidden xl:inline-block'
            onClick={() => {
              fetchErrorLogs()
              setShowErrorLog(!showErrorLog)
            }}
          >
            Error Log
          </button>
          <button
            className='bg-yellow-500 text-white py-1 px-4 rounded font-medium ml-3 hidden xl:inline-block'
            onClick={() => {
              fetchChangeLogs()
              setShowChangelog(!showChangelog)
            }}
          >
            Change Log
          </button>
          <button
            className='bg-white text-red-500 py-1 px-4 rounded font-medium ml-3 hidden xl:inline-block'
            onClick={logOutHandler}
          >
            Log Out
          </button>
        </div>
      </Header>
      <Content className='site-layout p-x-50 '>
        <div className='px-10 pt-5 h-min'>
          {selectedMenu === '1' && <Home />}
          {selectedMenu === '2' && <SlotDemo />}
          {selectedMenu === '3' && <Discount />}
          {selectedMenu === '4' && <Referral />}

          {showErrorLog ? (
            <div>
              <button
                className='flex text-4xl text-white items-center cursor-pointer fixed right-5 top-5 z-50'
                onClick={() => {
                  setShowErrorLog(!showErrorLog)
                }}
              >
                x
              </button>
            </div>
          ) : null}
          <div
            className={`top-0 right-0 w-[50vw] bg-slate-800  p-5 pb-36 text-white fixed h-full z-40  ease-in-out duration-300 ${
              showErrorLog ? 'translate-x-0 ' : 'translate-x-full'
            }`}
          >
            <div className='flex justify-start items-center'>
              <h3 className='text-4xl font-semibold text-white mb-5 mr-10'>
                Error Logs
              </h3>
              <button
                className='focus:outline-none text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-900 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 '
                type='button'
                onClick={handleErrorLogsDelete}
              >
                Delete
              </button>
            </div>
            <div className='bg-white w-full h-full overflow-x-hidden overflow-y-auto'>
              <table className='table-fixed w-full'>
                <thead>
                  <tr>
                    <th className='px-4 py-2 text-black font-bold w-24'>
                      DATE
                    </th>
                    <th className='px-4 py-2 text-black font-bold w-56'>
                      INFO
                    </th>
                    <th className='px-4 py-2 text-black font-bold '>ERROR</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map((error: any) => (
                    <tr key={error.id}>
                      <td className='border px-4 py-2 text-black text-xs'>
                        {new Date(error.createdAt).toLocaleString()}
                      </td>
                      <td
                        className='border px-4 py-2 text-black text-xs'
                        title={error.message}
                      >
                        {error.message}
                      </td>
                      <td
                        className='border px-4 py-2 text-black text-xs break-words'
                        title={error.payload}
                      >
                        {error.payload}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className='flex flex-col items-center mt-2'>
              <span className='text-sm text-gray-400'>
                Showing{' '}
                <span className='font-semibold  text-white'>
                  {indexOfFirstData + 1}
                </span>{' '}
                to{' '}
                <span className='font-semibold text-white'>
                  {Math.min(indexOfLastData, errorLogs.length)}
                </span>{' '}
                of{' '}
                <span className='font-semibold text-white'>
                  {errorLogs.length}
                </span>{' '}
                Entries
              </span>
              <div className='inline-flex mt-2 xs:mt-0'>
                <button
                  className='px-4 py-2 text-sm font-medium text-gray-400 bg-gray-500 rounded-l hover:bg-gray-700  border-gray-700 hover:text-white'
                  onClick={handlePrevButton}
                  disabled={currentPage === 1}
                >
                  Prev
                </button>
                <button
                  className='px-4 py-2 text-sm font-medium text-gray-400 bg-gray-500 border-0 border-l border-gray-700 rounded-r hover:bg-gray-700 hover:text-white'
                  onClick={handleErrorLogsNextButton}
                  disabled={
                    currentPage === Math.ceil(errorLogs.length / dataPerPage)
                  }
                >
                  Next
                </button>
              </div>
            </div>
          </div>

          {showChangelog ? (
            <div>
              <button
                className='flex text-4xl text-white items-center cursor-pointer fixed right-5 top-5 z-50'
                onClick={() => {
                  setShowChangelog(!showChangelog)
                }}
              >
                x
              </button>
            </div>
          ) : null}
          <div
            className={`top-0 right-0 w-[50vw] bg-slate-800  p-5 pb-36 text-white fixed h-full z-40  ease-in-out duration-300 ${
              showChangelog ? 'translate-x-0 ' : 'translate-x-full'
            }`}
          >
            <div className='flex justify-start items-center'>
              <h3 className='text-4xl font-semibold text-white mb-5 mr-10'>
                Change Logs
              </h3>
              <button
                className='focus:outline-none text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-900 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 '
                type='button'
                onClick={handleChangeLogsDelete}
              >
                Delete All Logs
              </button>
            </div>
            <div className='bg-white w-full h-full overflow-x-hidden overflow-y-auto'>
              <table className='table-fixed w-full'>
                <thead>
                  <tr>
                    <th className='px-4 py-2 text-black font-bold w-24'>
                      DATE
                    </th>
                    <th className='px-4 py-2 text-black font-bold w-56'>
                      USERNAME
                    </th>
                    <th className='px-4 py-2 text-black font-bold '>
                      CHANGE MADE
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {changeLogsSliced.map((log: any) => (
                    <tr key={log.id}>
                      <td className='border px-4 py-2 text-black text-xs'>
                        {new Date(log.createdAt).toLocaleString()}
                      </td>
                      <td
                        className='border px-4 py-2 text-black text-xs'
                        title={log.username}
                      >
                        {log.username}
                      </td>
                      <td
                        className='border px-4 py-2 text-black text-xs break-words'
                        title={log.ChangeLog}
                      >
                        {log.ChangeLog}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className='flex flex-col items-center mt-2'>
              <span className='text-sm text-gray-400'>
                Showing{' '}
                <span className='font-semibold  text-white'>
                  {indexOfFirstData + 1}
                </span>{' '}
                to{' '}
                <span className='font-semibold text-white'>
                  {Math.min(indexOfLastData, changeLogs.length)}
                </span>{' '}
                of{' '}
                <span className='font-semibold text-white'>
                  {changeLogs.length}
                </span>{' '}
                Entries
              </span>
              <div className='inline-flex mt-2 xs:mt-0'>
                <button
                  className='px-4 py-2 text-sm font-medium text-gray-400 bg-gray-500 rounded-l hover:bg-gray-700  border-gray-700 hover:text-white'
                  onClick={handlePrevButton}
                  disabled={currentPage === 1}
                >
                  Prev
                </button>
                <button
                  className='px-4 py-2 text-sm font-medium text-gray-400 bg-gray-500 border-0 border-l border-gray-700 rounded-r hover:bg-gray-700 hover:text-white'
                  onClick={handleChangeLogsNextButton}
                  disabled={
                    currentPage === Math.ceil(changeLogs.length / dataPerPage)
                  }
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
      <Footer className='text-center'>HASIL HOKI ©2023 Created by Ed</Footer>
    </Layout>
  )
}

export default Dashboard
